import { BackgroundLines } from '@components';
import { Box, Button, Stack, Typography } from '@mui/material';

const Content = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography width="fit-content" color="primary.main" variant="bodyM" fontWeight={600}>
        Алдаа: 404
      </Typography>
      <Typography textTransform="uppercase" fontSize={{ sm: 40, lg: 70 }} lineHeight="100%" fontWeight={{ sm: 500, md: 700 }} sx={{ transform: 'scaleY(0.76)' }} textAlign="center">
        Хуудас олдсонгүй
      </Typography>
      <Typography variant="bodyM" width="fit-content">
        Уучлаарай таны хайсан нүүр олдсонгүй
      </Typography>

      <Box mt={4}>
        <Button variant="outlined" href="/">
          Нүүр хэсэг рүү очих
        </Button>
      </Box>
    </Stack>
  );
};

const Page = () => {
  return (
    <Stack width="100vw" height="100%" alignItems="center">
      <BackgroundLines
        style={{
          height: '100%',
          margin: `0 !important`,
          padding: `0 !important`,
        }}
      >
        <Content />
      </BackgroundLines>
    </Stack>
  );
};

export default Page;
